import React from "react";
import Data from "../../utils/data";
import {useInput} from "../../hooks/inputHook";
import Api from "../../utils/api";
import Toast from "../../utils/toast";
import {Event} from '../../utils/event'

export function ContactForm() {

    const {value: name, bind: bindName, reset: resetName} = useInput("");
    const {value: emailAddress, bind: bindEmailAddress, reset: resetEmailAddress} = useInput("");
    const {value: message, bind: bindMessage, reset: resetMessage} = useInput("");

    const handleSubmit = (evt) => {
        evt.preventDefault();
        Event.progress(true);
        fetch(Data.API_BASE_URL + "inquiries", {
            method: 'POST',
            headers: Data.API_HEADER,
            body: JSON.stringify(
                {
                    full_name: name,
                    contact_number: "0",
                    email_address: emailAddress,
                    message: message
                }
            )
        }).then(Api.handleResponseAndError)
            .then(response => {
                resetName();
                resetEmailAddress();
                resetMessage();
                Toast.success(response.message);
                // Event.progress(false);
            })
            .catch(error => {
                Toast.error(error.message);
                // Event.progress(false);
            });
    };

    return <div className="col-md-4">
        <h3>Contact Form</h3>
        <form className="contact-form" onSubmit={handleSubmit}>
            <div>
                <input type="text" name="Your Full Name" placeholder="Your Full Name"  {...bindName} required/>
                <input type="email" name="Your Email" placeholder="Your Email"  {...bindEmailAddress} required/>
                <textarea placeholder="Your Message"  {...bindMessage} required/>
                <input className="submit" type="submit" name="Submit"/>
            </div>
        </form>
    </div>;
}
