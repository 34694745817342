import React from "react";
import {Link} from "react-router-dom";
import bottomLogo from '../../assets/images/bottom-logo.png'
import {
    ABOUT_US,
    APPROACHES,
    BOARD_MEMBERS,
    COMPLETED_PROGRAMS,
    CONTACT,
    HOME,
    INSTITUTIONAL_CAPACITY,
    ONGOING_PROGRAMS,
    STAFFS,
    STRATEGIES,
    STRUCTURE_OF_ORGANIZATION
} from "../../utils/path";
import {ContactForm} from "../contact/contact_form";

export function Footer() {
    return <div>
        <section className="footer-section-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <p><Link to="/">
                            <img alt="Everest Club Dailekh" className="img-responsive" src={bottomLogo}/>
                        </Link></p>
                        <p>Our willingness to create civilized & self reliance society of Karnali Province </p>
                        <ul>
                            <li><i className="fas fa-map-marker-alt"/>Narayan Municipality 5, Dailekh</li>
                            <li><i className="fas fa-phone"/>+977-089-420257</li>
                            <li><i className="fas fa-globe-americas"/>everestclubdailekh@gmail.com</li>
                        </ul>
                    </div>
                    <div className="col-md-5">
                        <h3>Pages</h3>
                        <ul className="site-info" style={{columnCount: 2}}>
                            <li><Link to={HOME}>Home</Link></li>
                            <li><Link to={ABOUT_US}>About Us</Link></li>
                            <li><Link to={STRUCTURE_OF_ORGANIZATION}>Structure of Organization</Link></li>
                            <li><Link to={APPROACHES}>Approaches</Link></li>
                            <li><Link to={STRATEGIES}>Strategies</Link></li>
                            <li><Link to={BOARD_MEMBERS}>Board Members</Link></li>
                            <li><Link to={STAFFS}>Staffs</Link></li>
                            <li><Link to={COMPLETED_PROGRAMS}>Completed Programs </Link></li>
                            <li><Link to={ONGOING_PROGRAMS}>Ongoing Programs</Link></li>
                            <li><Link to={INSTITUTIONAL_CAPACITY}>Institutional Capacity</Link></li>
                            <li><Link to={CONTACT}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <ContactForm/>
                    <div className="clearfix"/>
                </div>
            </div>

            <hr/>
            <div className="copyright_designed">
                Copyright &copy; 2020, Everest Club, Dailekh.<br/>
                Designed & Developed by&nbsp;
                <Link to="https://leapfuse.net/" target="_blank">
                    <b>Leapfuse Technology</b>
                </Link>
            </div>
        </section>
    </div>;
}