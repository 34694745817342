import React, {useEffect, useState} from "react";
import Title from "../common/title";
import Utils from "../../utils/util";
import {Event} from "../../utils/event";
import Data from "../../utils/data";
import Api from "../../utils/api";
import Toast from "../../utils/toast";

const NewsAndEventDetail = (props) => {

    const [newsAndEvent, setNewsAndEvent] = useState(null);

    useEffect(() => {
        fetchNewsAndEventDetail();
    }, []);

    function getNewsAndEventId() {
        return props.match.params[0];
    }

    function fetchNewsAndEventDetail() {
        Event.progress(true);
        fetch(Data.API_BASE_URL + "news/" + getNewsAndEventId(), {
            method: 'GET',
            headers: Data.API_HEADER
        }).then(Api.handleResponseAndError)
            .then(response => {
                setNewsAndEvent(response.data);
                Event.progress(false);
            })
            .catch(error => {
                Event.progress(false);
                Toast.error(error.message);
            });
    }

    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                {newsAndEvent == null ? (
                        <div className="text-center"> ....</div>
                    ) :
                    <div>
                        <Title title={newsAndEvent["title"]} subtitle="Make any occasion unforgettable"/>
                        <div className="innerpage-content">
                            <img alt={newsAndEvent["title"]} className="img-responsive center-block"
                                 src={Utils.imageToUrl(newsAndEvent["image"])}/>
                            <hr/>
                            {newsAndEvent["description"]}
                        </div>
                    </div>
                }
            </div>
        </div>
    </section>;
}
export default NewsAndEventDetail;