import React, {useEffect, useState} from 'react';
import {Slider} from "./slider";
import {Introduction} from "./introduction";
import Gallery from "./gallery";
import {Summary} from "./summary";
import NewsAndEvents from "./news_and_events";
import {Event} from "../../utils/event";
import Data from "../../utils/data";
import Api from "../../utils/api";
import Toast from "../../utils/toast";

export function Home() {

    const [newsAndEvents, setNewsAndEvents] = useState([]);
    const [gallery, setGallery] = useState([]);

    function fetchDataForHome() {
        Event.progress(true);
        fetch(Data.API_BASE_URL + "index", {
            method: 'GET',
            headers: Data.API_HEADER
        }).then(Api.handleResponseAndError)
            .then(response => {
                setNewsAndEvents(response.data["news_and_events"]);
                setGallery(response.data["gallery"]);
                Event.progress(false);
            })
            .catch(error => {
                Event.progress(false);
                Toast.error(error.message);
            });
    }

    useEffect(() => {
        fetchDataForHome();
    }, []);

    return <div>
        <Slider/>
        <Introduction/>
        <NewsAndEvents data={newsAndEvents}/>
        <Gallery data={gallery}/>
        <Summary/>
    </div>;
}