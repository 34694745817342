import React from "react";
import Title from "../common/title";

export function Approaches() {
    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="Approaches" subtitle="Help world, One help can change many lives"/>

                <div className="innerpage-content">
                    <h4>Rights-based Approach</h4>
                    <p>
                        Everest Club believes that the basic needs of people are their fundamental rights they are
                        entitled to through national constitution and international conventions ratified by the country.
                        In order to claim and enjoy those rights the people must be organized, empowered and enabled.
                        The claims on all rights must have constitutional basis and claimants must be informed. However,
                        all struggles for rights should be non-violent.
                    </p>
                    <h4>Empowerment Approach</h4>
                    <p>
                        Everest Club believes that the rights holders must be organized empowered with skill, knowledge,
                        information, and the strategic know-how. Once they are empowered they play the lead role and
                        grow self-assertive in their rights pursuit. This help build bottom -up pressure which
                        eventually makes duty-bearers responsible and accountable to their actions.
                    </p>
                    <h4>Participatory Approach</h4>
                    <p>
                        Everest Club believes that the rights holders should be involved throughout the entire cycle of
                        the project. They should have active role in planning, setting priorities, implementing and
                        social auditing. This increases sense of ownership in them and increases sustainability
                        prospects.
                    </p>
                    <h4>Sustainable Approach</h4>
                    <p>
                        Everest Club believes that the economic opportunities being created around Karnali province
                        should be utilized for the sustainable development of the large section of society. It can be
                        achieved through the use of communication, transportation, technology transfer, capital
                        formation and utilization towards a market-based or demand-led economy.Everest Club works with
                        community at the grassroots level (village/community/Ward) to encourage meaningful and inclusive
                        participation in decision-making by previously excluded groups. EC provides support to
                        disadvantaged populations to organize themselves and enhance their capacity and thereby acquire
                        the confidence and collective strength to participate effectively in local level decision-making
                        bodies. It develops its plan, program; implement; monitor and evaluate all of its programs in
                        participation with its rights holder communities. While aligning its program goal and objectives
                        with government it will include goals and objectives set forth by the rights holder community
                        that is developed through participatory approach. To increased efficiency and effectiveness,
                        Everest Club has its own program implementation approach. It gives priority to:
                    </p>
                    <ul>
                        <li>(i) improved results-based PME system</li>
                        <li>(ii) credible results monitoring and measurement system, which link outcomes with output and
                            activities, and
                        </li>
                        <li>(iii) gender-sensitive and inclusive participatory planning, monitoring and evaluation.</li>
                        <li>Everest shall revise its entire organisational PME system to be more result oriented.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>;
}