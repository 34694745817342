import React from "react";

export function Summary() {
    return <section className="rate-wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <p className="rate-content">
                        We are served since 27 years <br/>
                        to helpless people with trust <br/>
                        and we are happy
                    </p>
                </div>
                <div className="col-md-6">
                    <div className="Scriptcontent">
                        <div className="counters">
                            <div className="col-md-4">
                                <div className="counter-wrap">
                                    <div className="counter-icon-wrap"><i className="fas fa-award"/></div>
                                    <div className="counter">21</div>
                                    <p>Total Award</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="counter-wrap">
                                    <div className="counter-icon-wrap"><i className="fas fa-users"/></div>
                                    <div className="counter">76</div>
                                    <p>Total Volunteers</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="counter-wrap">
                                    <div className="counter-icon-wrap"><i className="fas fa-praying-hands"/></div>
                                    <div className="counter">14</div>
                                    <p>Total Programs</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"/>
            </div>
        </div>
    </section>;
}