import React from "react";
import {Link} from "react-router-dom";
import Utils from "../../utils/util";
import Data from "../../utils/data";
import {NEWS_AND_EVENT_DETAIL} from "../../utils/path";

const NewsAndEventItem = (props) => {
    const data = props.data;
    const date = new Date(data["updated_at"]);
    return <div className="col-md-4">
        <div className="event-img">
            <img alt={data["title"]} className="img-responsive" src={Utils.imageToUrl(data["image"])}/>
        </div>
        <div className="col-md-2">
            <div className="row">
                <div className="event-date">{date.getDay()}<br/>
                    {Data.MONTHS[date.getMonth()].substring(0, 3)}
                </div>
            </div>
        </div>
        <div className="col-md-10">
            <h4>{data["title"]}</h4>
            <p>{data["description"].substring(0, 60)}
                <Link to={NEWS_AND_EVENT_DETAIL.replace("*", data["id"])}>
                    read more
                </Link>
            </p>
        </div>
        <div className="clearfix"/>
    </div>;
}
export default NewsAndEventItem;