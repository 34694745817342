import React from "react";
import Title from "../common/title";

export function Strategies() {
    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="Strategies" subtitle="Help world, One help can change many lives"/>

                <div className="innerpage-content">
                    <h4>Pillar 1. Sustainable Livelihood :</h4>
                    <p>Marginalized and disadvantaged communities empowered to achieve resiliency and quality life</p>
                    <h4>Pillar 2. Climate Change Adaptation and Disaster risk reduction:</h4>
                    <p>People and communities are better prepared for, able to effectively respond to and timely recover
                        from climate change, disasters and emergencies</p>
                    <h4>Pillar 3. Governance and Justice:</h4>
                    <p>Priority populations are able to participate in decisions affecting them, improved local
                        governance
                        and to advocate for their rights)</p>
                    <ul>
                        <li>Diverse livelihood options</li>
                        <li>Integrated resource management</li>
                        <li>Linking field-level evidences with policy changes and advocacy</li>
                        <li>Partnering with private sectors:</li>
                        <li>Participatory action research:</li>
                        <li>Inclusive participation and engagement in local institutions</li>
                        <li>Results-based program planning and management by adopting results approach</li>
                        <li>Environment and climate change sensitivity</li>
                        <li>Integration and mainstreaming</li>
                        <li>Facilitate the marginalized beneficiaries, preferably, women, dalit and people living in the
                            remote areas to get organized in their own grassrooted institutions
                        </li>
                        <li>Share the values, good governance, skills and fair practices for the healthy growth of
                            individuals and institutions,
                        </li>
                        <li>Encourage them to seek sustainable solutions of challenges by maximum use of local resources
                            and
                            by letting them the external support for exactly deficit areas,
                        </li>
                        <li>Facilitate them to be responsive towards the positive changes, technology adoption, and a
                            shared
                            future,
                        </li>
                        <li>Facilitate them in owing the social responsibility including the social assets and
                            development
                            projects so as to prepare them for gradual phase over
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>;
}