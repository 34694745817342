import React from "react";
import Title from "../common/title";
import GalleryItem from "../gallery/gallery_item";

const Gallery = (props) => {
    return <section className="gallery-wrap">
        <Title title="Gallery" subtitle="Help world, One help can change many lives"/>
        {props.data.map(function (galleryItem, index) {
            return <GalleryItem data={galleryItem} key={index}/>;
        })}
        <div className="clearfix"/>
    </section>;
}

export default Gallery;