import Title from "../common/title";
import {Event} from "../../utils/event";
import Data from "../../utils/data";
import Api from "../../utils/api";
import Toast from "../../utils/toast";
import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom'

export function Programs() {

    const [programs, setPrograms] = useState([]);
    let location = useLocation();

    function fetchPrograms() {
        Event.progress(true);
        fetch(Data.API_BASE_URL + "programs?ongoing=" + (isOngoingProgram() ? 1 : 0)
            , {
                method: 'GET',
                headers: Data.API_HEADER
            })
            .then(Api.handleResponseAndError)
            .then(response => {
                setPrograms(response.data);
                Event.progress(false);
            })
            .catch(error => {
                Event.progress(false);
                Toast.error(error.message);
            });
    }

    function getPathName() {
        return location.pathname.toString();
    }

    function isOngoingProgram() {
        return getPathName() === "/ongoing-programs";
    }

    useEffect(() => {
        fetchPrograms()
    }, []);

    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title
                    title={isOngoingProgram() ? "Ongoing Programs" : "Completed Programs"}
                    subtitle="Help world, One help can change many lives"/>
                <div className="innerpage-content">
                    <div className="table-scroll">
                        <table width="100%">
                            <thead>
                            <tr>
                                <th rowSpan="2">S. No.</th>
                                <th rowSpan="2">Name of the assignment</th>
                                <th rowSpan="2">Name of the client served</th>
                                <th rowSpan="2">Address of the client</th>
                                <th rowSpan="2">Nature of Project</th>
                                <th colSpan="3">Duration of the assignment</th>
                                <th rowSpan="2">Total Cost of the assignment</th>
                                <th rowSpan="2">No. of professional person months used for the assignment</th>
                            </tr>
                            <tr>
                                <th>From<br/>
                                    (Date)
                                </th>
                                <th>To<br/>
                                    (Date)
                                </th>
                                <th>Total months</th>
                            </tr>
                            </thead>
                            <tbody>
                            {programs.map(function (program, index) {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{program["name"]}</td>
                                    <td>{program["client_name"]}</td>
                                    <td>{program["client_address"]}</td>
                                    <td style={{whiteSpace: "pre-line"}}>{program["nature_of_project"]}</td>
                                    <td>{program["assignment_from"]}</td>
                                    <td>{program["assignment_to"]}</td>
                                    <td>{program["total_months"]}</td>
                                    <td width="1px">{program["total_cost"]}</td>
                                    <td width="1px">{program["professional_months"]}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}