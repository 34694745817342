import React, {Component} from 'react'
import {toast} from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';
import SyncLoader from 'react-spinners/SyncLoader';
import 'react-toastify/dist/ReactToastify.css';
import {Event} from './utils/event'
import {Route, Switch} from "react-router-dom";
import {Header} from "./components/common/header";
import {Footer} from "./components/common/footer";
import Data from "./utils/data";
import {Home} from "./components/index/home";
import $ from 'jquery';
import {Contact} from "./components/contact/contact";
import {
    ABOUT_US,
    APPROACHES,
    BOARD_MEMBERS,
    COMPLETED_PROGRAMS,
    CONTACT,
    GALLERY,
    HOME,
    INSTITUTIONAL_CAPACITY,
    NEWS_AND_EVENT_DETAIL,
    NEWS_AND_EVENTS,
    ONGOING_PROGRAMS,
    STAFFS,
    STRATEGIES,
    STRUCTURE_OF_ORGANIZATION
} from "./utils/path";
import {InstitutionalCapacity} from "./components/institutional/institutional_capacity";
import {AboutUs} from "./components/about/about_us";
import {StructureOfOrganization} from "./components/about/structure_of_organization";
import {Approaches} from "./components/about/approaches";
import {Strategies} from "./components/about/strategies";
import {Programs} from "./components/programs/programs";
import {GalleryPage} from "./components/gallery/gallery";
import {NewsAndEvents} from "./components/news_and_events/news_and_events";
import NewsAndEventDetail from "./components/news_and_events/news_and_event_detail";
import {Team} from "./components/team/team";

class App extends Component {

    state = {
        progress: false
    };

    render() {
        toast.configure({hideProgressBar: true, closeButton: false, pauseOnFocusLoss: false});
        return (
            <div>
                <LoadingOverlay
                    fadeSpeed={0}
                    active={this.state.progress}
                    spinner={<SyncLoader size={24} color={'#59bd60'}/>}>
                    <div>
                        <Header/>
                        <Switch>
                            <Route path='/(dashboard|admin)' component={
                                () => {
                                    window.location.href = Data.DASHBOARD_URL;
                                    return null;
                                }}/>
                            <Route path={ABOUT_US} component={AboutUs}/>
                            <Route path={CONTACT} component={Contact}/>
                            <Route path={INSTITUTIONAL_CAPACITY} component={InstitutionalCapacity}/>
                            <Route path={STRUCTURE_OF_ORGANIZATION} component={StructureOfOrganization}/>
                            <Route path={APPROACHES} component={Approaches}/>
                            <Route path={STRATEGIES} component={Strategies}/>
                            <Route path={BOARD_MEMBERS} key={{BOARD_MEMBERS}} component={Team}/>
                            <Route path={STAFFS} key={STAFFS} component={Team}/>
                            <Route path={COMPLETED_PROGRAMS} key={COMPLETED_PROGRAMS} component={Programs}/>
                            <Route path={ONGOING_PROGRAMS} key={ONGOING_PROGRAMS} component={Programs}/>
                            <Route path={GALLERY} component={GalleryPage}/>
                            <Route path={NEWS_AND_EVENT_DETAIL} component={NewsAndEventDetail}/>
                            <Route path={NEWS_AND_EVENTS} component={NewsAndEvents}/>
                            <Route path={HOME} component={Home}/>
                        </Switch>
                        <Footer/>
                    </div>
                </LoadingOverlay>
            </div>
        )
    }

    componentDidMount() {
        Event.dispatcher.register(dispatch => {
            if (dispatch.type === Event.EVENT_PROGRESS) {
                this.setState({progress: dispatch.active})
                setTimeout(
                    function () {
                        const scrollPos = $(document).scrollTop() + ($(window).height() / 2);
                        $('._loading_overlay_content').attr('style', 'margin-top: ' + scrollPos + 'px !important');
                        $('._loading_overlay_overlay').attr('style', 'z-index: 10000 !important');
                    }, 10);
            }
        });
    }
}

export default App