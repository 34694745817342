import {Event} from "./event";

export default {

    handleResponseAndError(response) {
        Event.progress(false);
        if (!response.ok) {
            return response.text().then(text => {
                console.log(text);
                throw new Error(JSON.parse(text).message);
            })
        } else {
            return response.json()
        }
    }

}