import React from "react";

const Title = (props) => {
    return <div className="title">
        <h5>{props.title}</h5>
        <p>{props.subtitle}</p>
        <div className="separator">
            <span className="inner"/>
        </div>
    </div>;
}
export default Title