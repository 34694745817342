import React from "react";
import Title from "../common/title";

export function Contact() {
    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="Contact Us" subtitle="Join your hand with us for a better life and beautiful future."/>
                <div className="innerpage-content">
                    <h4>Google Map</h4>
                    <p>
                        <iframe
                            title="Everest Club, Dailekh"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d111818.92372992549!2d81.655044590267!3d28.858462803882663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a2eda3df5986fd%3A0x723c3e509cf32277!2sNarayan%20Municipality%2021600!5e0!3m2!1sen!2snp!4v1585025029014!5m2!1sen!2snp"
                            width="100%" height="450" frameBorder="0" style={{border: 0}}
                            allowFullScreen="true" aria-hidden="false" tabIndex="0"/>
                    </p>
                </div>
            </div>
        </div>
    </section>;
}