import React, {useEffect, useState} from "react";
import Title from "../common/title";
import GalleryItem from "./gallery_item";
import {Event} from "../../utils/event";
import Data from "../../utils/data";
import Api from "../../utils/api";
import Toast from "../../utils/toast";

export function GalleryPage() {

    const [gallery, setGallery] = useState([]);

    function fetchGallery() {
        Event.progress(true);
        fetch(Data.API_BASE_URL + "gallery", {
            method: 'GET',
            headers: Data.API_HEADER
        }).then(Api.handleResponseAndError)
            .then(response => {
                setGallery(response.data);
                Event.progress(false);
            })
            .catch(error => {
                Event.progress(false);
                Toast.error(error.message);
            });
    }

    useEffect(() => {
        fetchGallery()
    }, []);

    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="Gallery" subtitle="Make any occasion unforgettable"/>
                <div className="innerpage-content">
                    {gallery.map(function (galleryItem, index) {
                        return <GalleryItem data={galleryItem} key={index}/>;
                    })}
                    <div className="clearfix"/>
                </div>
            </div>
        </div>
    </section>;
}