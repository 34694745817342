import React from "react";
import {Link} from "react-router-dom";
import logo from '../../assets/images/logo.png'
import email from '../../assets/images/email.jpg'
import phone from '../../assets/images/phone.jpg'
import {
    ABOUT_US,
    APPROACHES,
    BOARD_MEMBERS,
    COMPLETED_PROGRAMS,
    CONTACT,
    GALLERY,
    HOME,
    INSTITUTIONAL_CAPACITY,
    NEWS_AND_EVENTS,
    ONGOING_PROGRAMS,
    STAFFS,
    STRATEGIES,
    STRUCTURE_OF_ORGANIZATION
} from "../../utils/path";

export function Header() {
    return <div>
        <section className="top-follow-wrap">
            <div className="container">
                <div className="row">
                    <div className="col-md-10">
                        <div className="row"><p>Our willingness to create civilized & self reliance society of Karnali
                            Province</p></div>
                    </div>
                    <div className="col-md-2">
                        <div className="row">
                            <div className="">
                                <ul>
                                    <li><Link className="hvr-icon-up" to="#" target="_blank">
                                        <i className="fab fa-facebook-f hvr-icon"/>
                                    </Link></li>
                                    <li><Link className="hvr-icon-up" to="#" target="_blank">
                                        <i className="fab fa-twitter hvr-icon"/>
                                    </Link></li>
                                    <li><Link className="hvr-icon-up" to="#" target="_blank">
                                        <i className="fab fa-google-plus-g hvr-icon"/>
                                    </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="top-logo">
            <div className="container">
                <div className="row">
                    <div className="main_logo col-md-3">
                        <div className="row">
                            <Link to="/">
                                <figure><img src={logo} title="Everest Club Dailekh"
                                             alt="Everest Club Dailekh"/>
                                </figure>
                            </Link>
                        </div>
                    </div>
                    <div className="mobile-top-contact col-md-9">
                        <div className="col-md-1">
                            <div className="top-contact-img"><img alt="Email" src={email}/></div>
                        </div>
                        <div className="col-md-4">
                            <p><b>Email</b><br/>everestclubdailekh@gmail.com</p>
                        </div>
                        <div className="col-md-1">
                            <div className="top-contact-img"><img alt="Phone" src={phone}/></div>
                        </div>
                        <div className="col-md-4">
                            <p><b>Call Now</b><br/>977089-420257</p>
                        </div>
                        <div className="clearfix"/>
                    </div>
                    <div className="clearfix"/>
                </div>
            </div>
        </section>
        <section className="top-menu sticky">
            <div className="container">
                <div className="row">
                    <div>
                        <div className="menu">
                            <div className="menu__bar">
                                <div className="stellarnav">
                                    <ul>
                                        <li><Link to={HOME}>Home</Link></li>
                                        <li><Link to="#">About Us</Link>
                                            <ul>
                                                <li><Link to={ABOUT_US}>About Us</Link></li>
                                                <li><Link to={STRUCTURE_OF_ORGANIZATION}>Structure of
                                                    Organization</Link></li>
                                                <li><Link to={APPROACHES}>Approaches</Link></li>
                                                <li><Link to={STRATEGIES}>Strategies</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="#">Our Team</Link>
                                            <ul>
                                                <li><Link to={BOARD_MEMBERS}>Board Members</Link></li>
                                                <li><Link to={STAFFS}>Staffs</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="#">Our Programs</Link>
                                            <ul>
                                                <li><Link to={COMPLETED_PROGRAMS}>Completed Programs </Link></li>
                                                <li><Link to={ONGOING_PROGRAMS}>Ongoing Programs</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to={INSTITUTIONAL_CAPACITY}>Institutional Capacity</Link></li>
                                        <li><Link to={NEWS_AND_EVENTS}>News & Events</Link></li>
                                        <li><Link to={GALLERY}>Gallery</Link></li>
                                        <li><Link to={CONTACT}>Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="clearfix"/>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"/>

                </div>
                <div className="clear-both"/>
            </div>
        </section>
    </div>;
}