import React from "react";
import {Link} from "react-router-dom";
import Title from "../common/title";

export function Introduction() {
    return <section className="welcome-wrap">
        <div className="container">
            <div className="row">
                <Title title="Introduction" subtitle="Help world, One help can change many lives"/>
                <div className="col-md-1"/>
                <div className="col-md-10">
                    <p>Everest Club was established in 1993 as a non-profit organization for cultural and social service
                        provider, especially development of health, education, sanitation, agriculture promotion, WASH
                        promotion, natural economy, social empowerment, disaster risk reduction and climate change
                        adaption etc in Dailekh, Kalikot, Jumla, jajarkot District as per aspiration of Bhery zone
                        region...
                        <Link to="about.php">read more</Link></p>
                </div>
                <div className="col-md-1"/>
                <div className="clearfix"/>
            </div>
        </div>
    </section>
}