class Data {

    static get BASE_URL() {
        // return "http://localhost/everest-club-web/public_html/"
        return "https://everestclubnepal.org.np/"
    }

    static get API_BASE_URL() {
        return this.BASE_URL + "api/public/"
    }

    static get IMAGE_BASE_URL() {
        return this.BASE_URL + "uploads/images/"
    }

    static get DASHBOARD_URL() {
        return this.BASE_URL + "dashboard/"
    }

    static get API_HEADER() {
        return {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        }
    }

    static get API_HEADER_WO_CONTENT_TYPE() {
        const header = Data.API_HEADER;
        delete header['Content-Type'];
        return header;
    }

    static get DEFAULT_IMAGE() {
        return "placeholder.png";
    }

    static get MONTHS() {
        return ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
    }
}

export default Data