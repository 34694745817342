import React from "react";
import Title from "../common/title";
import NewsAndEventItem from "../news_and_events/news_and_event_item";

const NewsAndEvents = (props) => {
    return <section>
        <div className="container">
            <div className="row">
                <Title title="News & Events" subtitle="Make any occasion unforgettable"/>
                <div>
                    {props.data.map(function (newsAndEvent, index) {
                        return <NewsAndEventItem data={newsAndEvent} key={index}/>;
                    })}
                    <div className="clearfix"/>
                </div>
            </div>
        </div>
    </section>;
}

export default NewsAndEvents;