export const HOME = "/"
export const CONTACT = "/contact";
export const INSTITUTIONAL_CAPACITY = "/institutional-capacity";
export const ABOUT_US = "/about-us";
export const STRUCTURE_OF_ORGANIZATION = "/structure-of-organization";
export const APPROACHES = "/approaches";
export const STRATEGIES = "/strategies";
export const BOARD_MEMBERS = "/board-members";
export const STAFFS = "/staffs";
export const COMPLETED_PROGRAMS = "/completed-programs";
export const ONGOING_PROGRAMS = "/ongoing-programs";
export const GALLERY = "/gallery";
export const NEWS_AND_EVENTS = "/news-and-events";
export const NEWS_AND_EVENT_DETAIL = "/news-and-events/*";