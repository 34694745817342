import React from "react";
import Title from "../common/title";

export function InstitutionalCapacity() {
    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="Institutional Capacity" subtitle="Help world, One help can change many lives"/>
                <div className="innerpage-content">
                    <h4>Institutional Capacity</h4>
                    <div className="table-scroll">
                        <table width="100%">
                            <thead>
                            <tr>
                                <th>S.N.</th>
                                <th>Name of Equipments</th>
                                <th>Unit</th>
                                <th>Qnty.</th>
                                <th>Present condition</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>Computor</td>
                                <td>Set</td>
                                <td>12</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Samsung Printer</td>
                                <td>Set</td>
                                <td>7</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Wooden Drawer</td>
                                <td>No.</td>
                                <td>10</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Wooden Table</td>
                                <td>No.</td>
                                <td>12</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Wooden Tea table</td>
                                <td>No.</td>
                                <td>7</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Sofa</td>
                                <td>Set</td>
                                <td>5</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Wooden Rack</td>
                                <td>No.</td>
                                <td>10</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Sony Digital Camera</td>
                                <td>No.</td>
                                <td>5</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Dolphin Chair</td>
                                <td>No.</td>
                                <td>45</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Wooden Chair</td>
                                <td>No.</td>
                                <td>10</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Steel Drawer</td>
                                <td>No.</td>
                                <td>10</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Fax / Photocopy Machine</td>
                                <td>No.</td>
                                <td>3</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Solar Penel 500Wat</td>
                                <td>Set</td>
                                <td>1</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>14</td>
                                <td>Laptop</td>
                                <td>set</td>
                                <td>15</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>15</td>
                                <td>Revolving Chair</td>
                                <td>No.</td>
                                <td>15</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>16</td>
                                <td>Tephone /Internet</td>
                                <td>No.</td>
                                <td>2</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>17</td>
                                <td>Projector</td>
                                <td>No.</td>
                                <td>2</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>18</td>
                                <td>Mobile</td>
                                <td>No.</td>
                                <td>25</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>19</td>
                                <td>White Board</td>
                                <td>No.</td>
                                <td>10</td>
                                <td>Well</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td>Generator</td>
                                <td>No.</td>
                                <td>1</td>
                                <td>Well</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <h4>Policies</h4>
                    <ul style={{columns: 2}}>
                        <li>HR policy</li>
                        <li>Finance policy</li>
                        <li>Child safeguarding policy</li>
                        <li>Gender Policy</li>
                        <li>Institutional Capacity Governance Policy</li>
                        <li>Humantarian frame work</li>
                        <li>Overhead utilization Policy</li>
                        <li>Communication Policy</li>
                        <li>Code of conduct for staff</li>
                        <li>Staff Capacity Development and Service Providing Policy</li>
                        <li>Zero tolerance Policy</li>
                        <li>Vehicle Operational Policy</li>
                    </ul>
                    <h4>Annual Financial turnover</h4>
                    <table width="50%">
                        <thead>
                        <tr>
                            <th>SN</th>
                            <th>Fiscal Year</th>
                            <th>Annual turnover (as per the audited report)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td>2075/076</td>
                            <td>57733239.55</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>2074/075</td>
                            <td>69079728.15</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>2073/074</td>
                            <td>63198626.66</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>2072/073</td>
                            <td>68341000</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>2071/072</td>
                            <td>24800048</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>2070/071</td>
                            <td>29767000</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>;

}