import React from "react";
import slider from "../../assets/images/slider.jpg"
import leftArrow from "../../assets/images/left-arrow.png"
import rightArrow from "../../assets/images/right-arrow.png"
import {Link} from "react-router-dom";

export function Slider() {
    return <section>
        <div id="myslideshow" className="carousel slide for-laptop-slider" data-ride="carousel">
            <div className="carousel-inner">
                <div className="item active">
                    <img src={slider} className="img-responsive" alt="Everest Club Dailekh"/>
                    <div className="carousel-caption">
                        <div className="slider-title">TO FEED AND EDUCATE!</div>
                        <h1>WE NEED YOUR SUPPORT</h1>
                        <div className="blue-block"><span>Become a Part of Change To The World</span></div>
                        <p>Our Company has a long tradition of charitable <br/>
                            giving and helping the needy ones.</p>
                        <p className="more">
                            <span><Link className="hvr-shutter-out-horizontal" to="about.php">Learn more</Link></span>
                        </p>
                    </div>
                </div>
            </div>

            <Link className="left carousel-control" to="#myslideshow" data-slide="prev">
                <span className="left-arrow"><img alt="Previous" src={leftArrow}/></span>
                <span className="sr-only">Previous</span>
            </Link>
            <Link className="right carousel-control" to="#myslideshow" data-slide="next">
            <span className="right-arrow">
                <img alt="Next" src={rightArrow}/></span>
                <span className="sr-only">Next</span>
            </Link>
        </div>
        <div className="highlight-wrap">
            <div className="highlight">
                <div className="slider-title"><span/>TO FEED AND EDUCATE!</div>
                <h2>WE NEED YOUR SUPPORT</h2>
                <p className="more">
                    <span><Link className="hvr-shutter-in-horizontal" to="about.php">
                        Learn More
                    </Link></span>
                </p>
            </div>
        </div>
    </section>;
}