import React from "react";
import Utils from "../../utils/util"

const GalleryItem = (props) => {
    const galleryItem = props.data;
    return <div className="col-md-3">
        <div className="row">
            <div className="gallery-image-wrap">
                <div className="gallery-image">
                    <a href={Utils.imageToUrl(galleryItem["image"])}
                       data-toggle="lightbox"
                       data-gallery="example-gallery">
                        <img alt={galleryItem.title} className="img-responsive img-fluid"
                             src={Utils.imageToUrl(galleryItem["image"])}/>
                    </a>
                </div>
            </div>
        </div>
    </div>;
}

export default GalleryItem;
