import Title from "../common/title";
import {Event} from "../../utils/event";
import Data from "../../utils/data";
import Api from "../../utils/api";
import Toast from "../../utils/toast";
import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom'


export function Team() {

    const [team, setTeam] = useState([]);
    let location = useLocation();

    function fetchTeam() {
        Event.progress(true);
        fetch(Data.API_BASE_URL + "team" + (isBoardMember() ? "?board-members" : ""), {
            method: 'GET',
            headers: Data.API_HEADER
        }).then(Api.handleResponseAndError)
            .then(response => {
                setTeam(response.data);
                Event.progress(false);
            })
            .catch(error => {
                Event.progress(false);
                Toast.error(error.message);
            });
    }

    function getPathName() {
        return location.pathname.toString();
    }

    function isBoardMember() {
        return getPathName() === "/board-members";
    }

    useEffect(() => {
        fetchTeam()
    }, []);

    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title
                    title={isBoardMember() ? "Board Members" : "Staffs"}
                    subtitle={isBoardMember() ? "Our board members" : "Our staffs"}/>
                <div className="innerpage-content">
                    <p style={{textAlign: "center"}}>
                        {isBoardMember() ?
                            "Everest Club (EC), Dailekh is managed by an Twenty - member Board of Members. More details is furnished in the table below:"
                            : "Everest Club has 140 staffs. Among of 140 staffs there are 54 community level paid volunteers as a health and nutrition facilitators."}
                    </p>
                    <div className="table-scroll">
                        <table width="100%">
                            <thead>
                            <tr>
                                <th>S.N</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Designation</th>
                                <th>Qualification</th>
                                <th>Contact Cell Number</th>
                            </tr>
                            </thead>
                            <tbody>
                            {team.map(function (teamItem, index) {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{teamItem["name"]}</td>
                                    <td>{teamItem["address"]}</td>
                                    <td>{teamItem["designation"]}</td>
                                    <td>{teamItem["qualification"]}</td>
                                    <td>{teamItem["contact_number"]}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}