import React from "react";
import Title from "../common/title";
import about1 from "../../assets/images/about/about-img.jpg"
import about2 from "../../assets/images/about/about-img2.jpg"

export function AboutUs() {
    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="About Us" subtitle="We wish to bring many good things to life."/>
                <div className="innerpage-content">
                    <h4>Background</h4>
                    <p>
                        <img alt="Everest Club Dailekh" className="img-right" src={about1}/>
                        Everest Club was established
                        in 1993 as a
                        non-profit organization for cultural and social service provider, especially development of
                        health,
                        education, sanitation, agriculture promotion, WASH promotion, natural economy, social
                        empowerment,
                        disaster risk reduction and climate change adption etc in Dailekh, Kalikot, Jumla, jajarkot
                        District
                        as per aspiration of Bhery zone region.
                    </p>
                    <p>
                        From the beginning, it has been working to implement sustainable development with primary focus
                        on
                        the deprived and disadvantaged community by qualified, experienced professional and dedicated
                        young
                        hands. Now NGO is working for creation of self- reliance society through improve their (Focused
                        community) socio-economic status. Everest club is working in the area of renewal energy
                        promotion,
                        WASH promotion, drinking water scheme functionality, income generation, sustainable soil
                        management,
                        small irrigation; Marketing, advocacy and networking, empowerment and organization development
                        to
                        creation of self- reliance society through improve their (Focused community) socio-economic
                        status.
                    </p>
                    <p>
                        The Everest club, Dailekh gives the high priority in it's all program for gender, environment,
                        sanitation and hygiene as a cross-cutting theme.
                    </p>
                    <p>
                        Everest Club (EC) has been implementing the MEDPA program in Jajarkot District as a lead NGO to
                        creation the new entrepreneur from the fiscal year 2074/075 where has the HRDC as a associate
                        patterned of Everest Club. Everest Club had completed the Home Guarding Project (HGP-IV) in four
                        district (Dailekh, Jajarkot, Kalikot and Jumla) of Karnali province of Nepal which was funded by
                        SDC
                        and LIBIRD. SDC and LIBIRD directly selected to the Everest Club as a partner to implement the
                        HGP-IV project in respected District. Everest Club had selected the associate partners to HRDC
                        Jajarkot, RDN kalikot and RDGP Jumla to implement the HGP-IV project.
                    </p>
                    <p>
                        <img alt="Everest Club Dailekh" className="img-left" src={about2}/>
                        Everest Club (EC), Dailekh is one of the
                        leading non-governmental organizations of Karnali province and was established in 1993 as a
                        nonprofit organization in Dailekh District administration office. The Everest Club (EC) was
                        established by local youths with aim to create civilized and self reliance Society. It has been
                        working in various sectors like Social mobilization, Organizational development, Income
                        generation,
                        Capacity development, Entrepreneurship development, appropriate technology transformation,
                        Renewal
                        energy promotion, Sustainable soil management technology promotion, Marketing, infrastructure,
                        climate change adaptation, DRR, & WASH promotion by collaborating with different donors
                        (RERL/DEES
                        Dailekh, SSMP Helvetas, LINK Helvetas, COPILA Helvatas, Live helvetas, LILI Helvetas, LWF
                        Nepal/Nepal WASH alliance, PAF Nepal, RAP, FAO, SNV Nepal, Second Small Town Project, MSFP/IDS,
                        MSFP/SSU, MEDPA/CSIDB, MEDEP, Oxfam Save the children, USAID/HKI, RVWRMP) in 7 rural
                        municipality
                        and 4 municipality of Dailekh District. Surkhet to Dailekh road corridor and Surkhet to Kalikot
                        road
                        corridor is also program area of the NGO for agriculture based marketing to implement the farmer
                        business school and business plan preparation. From the beginning, it has been working to
                        implement
                        sustainable development with primary focus on the deprived and disadvantaged community by
                        qualified,
                        experienced professional and dedicated young hands. Now NGO is working for creation of self-
                        reliance society through improve their (Focused community) socio-economic status. We hereby
                        declare
                        that all community level activities of the CAPA/LAPA, DRR, Sustainable soil management
                        technology
                        transformation, Environment improvement plan preparation, livelihood improvement plan
                        preparation,
                        essential infrastructure development, community practice in school for learning climate change
                        adoption & agriculture, skill, service and tourism based enterprises development activities are
                        successfully performed by Everest Club.
                    </p>
                    <p>
                        Everest Club believes that the basic needs of people are their fundamental rights they are
                        entitled
                        to through national constitution and international conventions ratified by the country. In order
                        to
                        claim and enjoy those rights the people must be organized, empowered and enabled. The claims on
                        all
                        rights must have constitutional basis and claimants must be informed. However, all struggles for
                        rights should be non-violent.
                    </p>
                    <p>
                        Everest Club believes that the rights holders must be organized empowered with skill, knowledge,
                        information, and the strategic know-how. Once they are empowered they play the lead role and
                        grow
                        self-assertive in their rights pursuit. This help build bottom -up pressure which eventually
                        makes
                        duty-bearers responsible and accountable to their actions.
                    </p>
                    <p>
                        Everest Club believes that the rights holders should be involved throughout the entire cycle of
                        the
                        project. They should have active role in planning, setting priorities, implementing and social
                        auditing. This increases sense of ownership in them and increases sustainability prospects.
                    </p>
                    <div className="about-bg-wrap">
                        <div className="col-md-4">
                            <h4><i className="fas fa-fire"/> Vision</h4>
                            <p>To create self-reliance society</p>
                        </div>
                        <div className="col-md-4">
                            <h4><i className="fas fa-rocket"/> Mission</h4>
                            <p>be always active to improve livelihood of disadvantage communities through skill enhance
                                and
                                capacity building</p>
                        </div>
                        <div className="col-md-4">
                            <h4><i className="fas fa-bullseye"/> Goal</h4>
                            <p>To be always active to improve livelihood of disadvantage communities through skill
                                enhance
                                and capacity building</p>
                        </div>
                        <div className="clearfix"/>
                    </div>
                    <h4>Objectives</h4>
                    <p>Everest Club has following objectives:</p>
                    <ul>
                        <li>To increase access to services and resources by empowering knowledge of Poor, vulnerable and
                            disadvantaged group.
                        </li>
                        <li>To mobilize and promote local resources and means for sustainable Development</li>
                        <li>To increase employment through promoting of agriculture based, forestry based, skill based,
                            service based and tourism based entrepreneurship development and technology transfer
                        </li>
                        <li>To promote agriculture commercial production through community Organization.</li>
                        <li>To develop and promote Local infrastructures</li>
                        <li>To create positive attitude towards safe motherhood.</li>
                        <li>To develop sectoral expertise on human resources.</li>
                        <li>To Deliver the WASH and Irrigation related basic services.</li>
                        <li>To Promote the Gender, equity and social inclusion at community Level.</li>

                    </ul>
                    <h4>Values</h4>
                    <ul style={{columns: 2}}>
                        <li>Inclusion and respect for diversity</li>
                        <li>Organizational development and decentralization</li>
                        <li>Increase participation</li>
                        <li>Focus on Gender, Equity and social inclusion</li>
                        <li>Dignity and governance</li>
                        <li>Transparency and Accountability</li>
                        <li>Coordination and collaboration</li>
                        <li>Promote sustainable development</li>
                        <li>Internalization of democratic practices</li>
                        <li>Human right and governance</li>
                        <li>Focus on national priority and campaign</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>;
}