import React, {useEffect, useState} from "react";
import NewsAndEventItem from "./news_and_event_item";
import Title from "../common/title";
import Data from "../../utils/data";
import Api from "../../utils/api";
import Toast from "../../utils/toast";
import {Event} from "../../utils/event";

export function NewsAndEvents() {

    const [newsAndEvents, setNewsAndEvents] = useState([]);

    function fetchNewsAndEvents() {
        Event.progress(true);
        fetch(Data.API_BASE_URL + "news", {
            method: 'GET',
            headers: Data.API_HEADER
        }).then(Api.handleResponseAndError)
            .then(response => {
                setNewsAndEvents(response.data);
                Event.progress(false);
            })
            .catch(error => {
                Event.progress(false);
                Toast.error(error.message);
            });
    }

    useEffect(() => {
        fetchNewsAndEvents()
    }, []);

    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="News & Events" subtitle="Make any occasion unforgettable"/>
                <div className="innerpage-content">
                    {newsAndEvents.map(function (newsAndEvent, index) {
                        return <NewsAndEventItem key={index} data={newsAndEvent}/>
                    })}
                </div>
            </div>
        </div>
    </section>;
}