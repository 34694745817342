import React from "react"
import Title from "../common/title";
import chart from "../../assets/images/about/chart.png"

export function StructureOfOrganization() {
    return <section className="innerpage-wrapper">
        <div className="container">
            <div className="row">
                <Title title="Structure of Organization" subtitle="Everest Club,Dailekh"/>
                <div className="innerpage-content">
                    <p>
                        <img alt="Structure of Organization" className="img-responsive center-block" src={chart}/>
                    </p>
                    <div className="table-scroll">
                        <table width="100%">
                            <thead>
                            <tr>
                                <th>S.N.</th>
                                <th>Concern Body</th>
                                <th>Affiliated</th>
                                <th>Register No.</th>
                                <th>Last renewal date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>District Administration Office, Dailekh</td>
                                <td>2050</td>
                                <td>11</td>
                                <td>2077/03/31</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Social welfare council</td>
                                <td>2051</td>
                                <td>1795</td>
                                <td>2078/03/31</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>NGO federation Nepal</td>
                                <td>2056</td>
                                <td>MWR-DAI-5</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td> PAN/VAT Registration Certificate.</td>
                                <td>2059</td>
                                <td>300964958</td>
                                <td>&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}