import {toast} from "react-toastify";

export default {
    success(message) {
        toast.success(message)
    },

    error(message) {
        toast.error(message)
    }
}